import { Router } from '@angular/router';
import { CommonAccountVerifyService } from '../common-myaccount-service/common-account-verify.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../common-myaccount-service/common-account-verify.service";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, authservice) {
        this.router = router;
        this.authservice = authservice;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        var url = state.url;
        return this.verifyLogin(url);
    };
    /** comment
     * verify user login
     */
    AuthGuard.prototype.verifyLogin = function (url) {
        if (!this.isLoggedIn()) {
            this.authservice.logout();
            this.authservice.redirectUrl = url;
            this.router.navigate(['/signin'], { queryParams: { returnUrl: url } });
            return false;
        }
        else if (this.isLoggedIn()) {
            return true;
        }
    };
    AuthGuard.prototype.isLoggedIn = function () {
        var status = false;
        if (this.authservice.checkLoggedIn()) {
            status = true;
        }
        else {
            status = false;
        }
        return status;
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.Router), i0.inject(i2.CommonAccountVerifyService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
