<div class="clearfix"></div>
<div class="loader-bg">
<img src="assets/images/spiner.png">
</div>
<!--slideshow start here-->
<div class="home-slider-wrap">
   <div class="home-slider">
      <owl-carousel [options]="{items: 1, dots: true,loop: true,
            navigation: true, autoplay: true, autoplayTimeout: 5000 ,autoplaySpeed: 1000 }" [items]="banner"
         [carouselClasses]="['owl-theme','sliding']" >
         <div class="slide" *ngFor="let homebanner of banner" [ngStyle]="{'background': 'url(' + homebanner?.image + ') no-repeat top center'}">
            <img src="{{homebanner?.image}}" class="full">
            <div class="overlay">
               <h2>{{homebanner?.title}}</h2>
               <h3>{{homebanner?.description}}</h3>
            </div>
         </div>
      </owl-carousel>
   </div>
   <a data-class="upcoming-event"><img src="assets/images/arrow-down.png" class="arrow-down cursor"></a>
</div>
<!--slideshow ends here-->
<div class="clearfix"></div>
<section class="upcoming-event" *ngIf="images?.length > 0">
   <div class="container">
      <div class="text-center">
         <h2 class="custom-hd text-center">Upcoming <label>Sessions</label> <span></span></h2>
      </div>

      <!-- updated on 02-03-24 -->
      <!-- <div class="col-md-4 text-center" *ngFor="let category of categoryListing">
         <a
            [routerLink]="['/11plus-help-club/category',category.category_slug]" class="btn btn-custom org btn-hover goto"><i
                  class="fa fa-caret-right" aria-hidden="true"></i> {{category.category_name}}</a>
      </div> -->
      <!-- end updation -->
      <div class="clearfix"></div>
      <br />
      <br />
      <div class="row upcoming-events upcoming-event-section events">

         <owl-carousel [options]="{items: 3, dots: false, navigation: false, responsive: { '0': {  items: 1}, '480': { items: 1},
            '1200': { items: 3}  } }" [items]="images" [carouselClasses]="['owl-theme', 'sliding']">
            <div class="col-md-4 item" *ngFor="let image  of images">
               <a [routerLink]="['/11plus-help-club/category/',image.category_slug,image.test_slug]"
                  routerLinkActive="router-link-active" class="goto">
                  <div class="img">
                     <img src="{{image?.test_image}}" class="full transition" />
                     <div class="overlay transition"><i class="fa fa-link transition" aria-hidden="true"></i></div>
                  </div>
               </a>
               <h2>
                  <a  [routerLink]="['/11plus-help-club/category/',image.category_slug,image.test_slug]" class="goto">{{image?.test_name }}</a>
               </h2>
               <h4 *ngIf="image?.test_location == ''"><img src="assets/images/map1.png" /> <span class="badge badge-danger">Online Session</span></h4>
               <h4 *ngIf="image?.all_location == '' && image?.test_location != ''"><img src="assets/images/map1.png" /> Location: {{image?.test_location }} </h4>
               <h4 class="tooltip-parent" *ngIf="image?.all_location != ''">
                  <img src="assets/images/map1.png" /> Location: {{image?.test_location }} 
                  <div class="tooltip">
                     <span>{{image?.all_location}}</span>
                  </div> 
               </h4>
               <span class="date transition" *ngIf="image?.test_date != ''"><img src="assets/images/date.png" />{{image.test_date}}</span>
               <div class="clearfix"></div>
               <div class="multiple-location"><img src="assets/images/multiple-location.png" /> <a href="#"
                     class="border-effect transition a-link">View more location</a></div>
            </div>
         </owl-carousel>



      </div>
   </div>
   <ng-container *ngIf="siteSetting?.view_all_button == 1">
   <div class="clearfix"></div>
   <br />
   <br class="res-hide" />
   <div class="text-center">
      <a [routerLink]="['/11plus-help-club/category']" class="btn btn-custom org btn-hover goto">View all <i
            class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
   </div>
</ng-container>
</section>
<!-- category section newly added 02-03-24 -->
<!-- <div class="clearfix"></div>
<section class="category">
   <div class="container">
      <div class="text-center">
         <h3 class="custom-hd text-center">Category</h3>
      </div>
      <div class="clearfix"></div>
      <div class="features-section">
         <div class="col-md-4 category-link" *ngFor="let category of categoryListing">
            <h3 style="color: white; font-weight: 400; margin-top: 2px;">
               <a
                  [routerLink]="['/11plus-help-club/category',category.category_slug]" class="links"><i
                      class="fa fa-caret-right" aria-hidden="true"></i> {{category.category_name}}</a>
            </h3>
         </div>
      </div>
   </div>
</section> -->
<!-- category section newly added 02-03-24 end -->
<div class="clearfix"></div>
<section class="about">
   <div class="container">
      <div class="text-center">
         <h2 class="custom-hd text-center">Welcome to <label>Sehyog</label> <span class="test-uppercase"> </span> </h2>
      </div>
      <div class="clearfix"></div>
      <br />
      <br />
      <div class="col-md-7 editor">
         <h3>{{welcome?.title}}</h3>
         <div class="text text-justify" [innerHTML]="welcome?.content">
         </div>
         <div class="clearfix"></div>
         <br />
         <a [routerLink]="['/11plus-help-club']" class="btn btn-custom org btn-hover goto">Read More <i
               class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
      </div>
      <div class="col-md-5 text-center">
         <div class="about-img hide">
            <div class="img">
               <img src="{{welcome?.image}}" class="full" />
            </div>
         </div>
         <img src="{{welcome?.image}}" style="margin-left:-40px;" class="res-full" />
      </div>
   </div>
</section>
<div class="clearfix"></div>
<section class="features">
   <div class="container">
      <div class="text-center">
         <h2 class="custom-hd text-center">11plusHELPclub</h2>
         <h3>How it Helps <span></span></h3>
      </div>
      <div class="clearfix"></div>
      <br class="res-hide" />

      <div class="features-section">
         <div class="col-md-4" *ngFor="let help of howithelp">
            <img src="{{help?.icon}}" />
            <h3 class="text-white">
               {{help?.heading}}
            </h3>
         </div>
      </div>
      <div class="clearfix"></div>
      <br class="res-hide" />
      <br class="res-hide" />
      <br />
      <div class="text-center">
         <a [routerLink]="['/11plus-help-club']" class="btn btn-custom white btn-hover goto">Read More <i
               class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
      </div>
   </div>
</section>
<div class="clearfix"></div>
<section class="gallery">
   <div class="container">
      <div class="col-md-6 res-hide">
         <img src="assets/images/gallery2.png" class="res-full " />
      </div>
      <div class="col-md-6">
         <h2><strong>TAKE</strong> A <strong>LOOK</strong>
            <br />
            WHERE <strong>WE</strong> HAVE BEEN
            <br />
            UP TO <strong>RECENTLY</strong>
         </h2>
         <div class="clearfix"></div>
         <br />
         <ul class="list list-inline text-center pull-right">
            <li><a [routerLink]="['/sehyog/photo-gallery']" class="btn btn-custom border btn-hover goto">Photos <i
                     class="fa fa-long-arrow-right" aria-hidden="true"></i></a></li>
            <li><a [routerLink]="['/sehyog/video-gallery']" class="btn btn-custom org btn-hover goto">Videos <i
                     class="fa fa-long-arrow-right" aria-hidden="true"></i></a></li>
         </ul>
      </div>
      <div class="col-md-6 hide res-show">
         <img src="assets/images/gallery2.png" class="res-full " />
      </div>
   </div>
</section>
<div class="clearfix"></div>
<section class="bottom-boxes">
   <div class="container">
      <div class="col-md-4">
         <a [routerLink]="['/become-a-volunteer']" class="goto"><img src="assets/images/banner2.jpg" /></a>
      </div>
      <div class="col-md-4">
         <a [routerLink]="['//11plus-help-club/get-inspired/testimonial']" class="goto" routerLinkActive="router-link-active"><img
               src="assets/images/banner3.jpg" /></a>
      </div>
      <div class="col-md-4">
         <a [routerLink]="['/donation']" class="goto"><img src="assets/images/banner1.jpg" /></a>
      </div>
   </div>
</section>