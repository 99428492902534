import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../app/guards/auth.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', loadChildren: '../app/front-layout/front-layout.module#FrontLayoutModule' },
  { path: 'signin', loadChildren: '../app/signin/signin.module#SigninModule' },
  { path: 'forgot-password', loadChildren: '../app/forget-password/forget-password.module#ForgetPasswordModule' },
  { path: 'signup', loadChildren: '../app/signup/signup.module#SignupModule' },
  { path: 'dashboard', loadChildren: '../app/my-account/my-account.module#MyAccountModule', canActivate: [AuthGuard] },
  { path: 'vendor-dashboard', loadChildren: '../app/vendor-dashboard/vendor-dashboard.module#VendorDashboardModule', canActivate: [AuthGuard] },
  { path: 'email-verify/:id', loadChildren: '../app/verify-email/verify-email.module#VerifyEmailModule' },
  { path: '**', redirectTo: '/page-not-found'},
  { path: 'page-not-found', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
