import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
var CmsserviceService = /** @class */ (function () {
    function CmsserviceService(http, router) {
        this.http = http;
        this.router = router;
        this.serverUrl = environment.baseURL;
        this.AdminCommonData = new ReplaySubject(1);
    }
    /** comment
     * to cms page get the data
     */
    CmsserviceService.prototype.getCmsData = function (pageslug) {
        return this.http.get(this.serverUrl + "api/f/get_cms_page_data/" + pageslug).pipe(map(function (data) {
            return data;
        }));
    };
    /** comment
     * get faq category
     */
    CmsserviceService.prototype.getFaqCategoryList = function () {
        return this.http.get(this.serverUrl + "api/f/get_faq_categories").pipe(map(function (data) {
            return data;
        }));
    };
    /** comment
     * get faq category records
     */
    CmsserviceService.prototype.getFaqCategoryData = function (slugFaq) {
        return this.http.get(this.serverUrl + "api/f/get_faq/" + slugFaq).pipe(map(function (data) {
            return data;
        }));
    };
    /** comment
     * submit contact us form, get in touch form, support form
     */
    CmsserviceService.prototype.submitFormsService = function (formData) {
        return this.http.post(this.serverUrl + "api/f/form_submit", formData).pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * get test categories
     */
    CmsserviceService.prototype.getTestCategories = function () {
        return this.http.get(this.serverUrl + "api/f/test_categories").pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * get previous test categories
     */
    CmsserviceService.prototype.getPreviousTestCategories = function () {
        return this.http.get(this.serverUrl + "api/f/previous_test_categories").pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * get test by category
     */
    CmsserviceService.prototype.getAllTestByCategory = function (slug, paging) {
        return this.http.post(this.serverUrl + "api/f/category_test_listing/" + slug, paging).pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * get previous test by category
     */
    CmsserviceService.prototype.getAllPreviousTestByCategory = function (slug, paging) {
        return this.http.post(this.serverUrl + "api/f/category_previous_test_listing/" + slug, paging).pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * get test
     */
    CmsserviceService.prototype.getTest = function (parent, test) {
        var user = +localStorage.getItem('userid');
        var user_id = ((user) ? user : 0);
        return this.http.post(this.serverUrl + "api/f/test_detail/" + parent + '/' + test, { user_id: user_id }).pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * get previous test
     */
    CmsserviceService.prototype.getPreviousTest = function (parent, test) {
        var user = +localStorage.getItem('userid');
        var user_id = ((user) ? user : 0);
        return this.http.post(this.serverUrl + "api/f/previous_test_detail/" + parent + '/' + test, { user_id: user_id }).pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * photo gallary
     */
    CmsserviceService.prototype.photoGallery = function (pageNum, categoryId) {
        return this.http.post(this.serverUrl + "api/f/photo_gallery", { page: pageNum, category_id: categoryId }).pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * video gallary
     */
    CmsserviceService.prototype.vodeoGallery = function (pageNum) {
        return this.http.post(this.serverUrl + "api/f/video_gallery", { page: pageNum }).pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * get team record
     */
    CmsserviceService.prototype.getTeamList = function () {
        return this.http.post(this.serverUrl + "api/f/team", {}).pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * get success story
     */
    CmsserviceService.prototype.getSuccessStory = function (pageNum) {
        return this.http.post(this.serverUrl + "api/f/success_stories", { page: pageNum }).pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * get testimonials list
     */
    CmsserviceService.prototype.getTestimonials = function (pageNum) {
        return this.http.post(this.serverUrl + "api/f/testimonials", { page: pageNum }).pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * get setting
     */
    CmsserviceService.prototype.commonSiteSetting = function () {
        var _this = this;
        var user = +localStorage.getItem('userid');
        var user_id = ((user) ? user : 0);
        this.http.post(this.serverUrl + "api/f/site_settings", { user_id: user_id }).subscribe(function (res) {
            _this.AdminCommonData.next(res['data']);
        });
    };
    /** comment
     * get support reasons
     */
    CmsserviceService.prototype.getSupportFormReason = function () {
        return this.http.get(this.serverUrl + "api/f/support_reasons").pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * get donation reasons
     */
    CmsserviceService.prototype.donationReasons = function () {
        return this.http.get(this.serverUrl + "api/f/donation_reasons").pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * get upcoming events
     */
    CmsserviceService.prototype.upcomingEventsRecord = function () {
        return this.http.get(this.serverUrl + "api/f/upcoming_events_home").pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * get setting singleton service
     */
    CmsserviceService.prototype.commonSiteSettingSingleton = function () {
        return this.AdminCommonData.asObservable();
    };
    /** comment
     * home banner
     */
    CmsserviceService.prototype.homeBanner = function () {
        return this.http.get(this.serverUrl + "api/f/home_banner").pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * get filter list
     */
    CmsserviceService.prototype.getTestFilters = function () {
        return this.http.get(this.serverUrl + "api/f/test_list_filters").pipe(map(function (res) {
            return res;
        }));
    };
    CmsserviceService.prototype.getCategoryDetailBySlug = function (slug) {
        return this.http.get(this.serverUrl + "api/f/category_by_slug/" + slug).pipe(map(function (res) {
            return res;
        }));
    };
    CmsserviceService.prototype.getTestList = function (formData) {
        return this.http.post(this.serverUrl + "api/f/test_list", formData).pipe(map(function (res) {
            return res;
        }));
    };
    CmsserviceService.prototype.getPreviousTestList = function (formData) {
        return this.http.post(this.serverUrl + "api/f/previous_test_list", formData).pipe(map(function (res) {
            return res;
        }));
    };
    CmsserviceService.ngInjectableDef = i0.defineInjectable({ factory: function CmsserviceService_Factory() { return new CmsserviceService(i0.inject(i1.HttpClient), i0.inject(i2.Router)); }, token: CmsserviceService, providedIn: "root" });
    return CmsserviceService;
}());
export { CmsserviceService };
