import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CmsserviceService } from "src/app/common-cms-service/cmsservice.service";

@Component({
    selector: 'app-upcoming-events',
    templateUrl: './upcoming-events.component.html',
    styleUrls: ['./upcoming-events.component.css']
})
export class UpcomingEventsComponent implements OnInit {
    topContent : object = {content:'Hello'};
    slug: string;
    pagetitle: string;
    fields = [];
    ngUnsubscribe = new Subject<void>();
    constructor(
        private router: Router, 
        private cmsService: CmsserviceService,
        private titleService: Title) {}

    ngOnInit() {
        this.slug = this.router.url;
        this.onLoad();
    }

    /** comment
   * to get (inception story content) cmspage data
   */
   onLoad() {
        $('.loader-bg').fadeIn();
        const pageName = this.slug.split('/')[(this.slug.split('/').length - 1)];
        this.cmsService.getCmsData(pageName)
        .pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        this.pagetitle = res.data.page_title;
        this.fields = res.data.fields;
        this.titleService.setTitle(this.pagetitle);
        $('.loader-bg').fadeOut();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}