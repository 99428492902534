import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
var CommonAccountVerifyService = /** @class */ (function () {
    function CommonAccountVerifyService(httpClient, cookieService) {
        this.httpClient = httpClient;
        this.cookieService = cookieService;
        this.baseUrl = environment.baseURL;
        this.isUserLoggedIn = false;
        this.title = new BehaviorSubject('Initial Title');
        this.link = new BehaviorSubject('');
        this.jwtHelper = new JwtHelperService();
    }
    /** comment
     * signup user form
     */
    CommonAccountVerifyService.prototype.singUpUser = function (formData) {
        return this.httpClient.post(this.baseUrl + "api/f/user_signup", formData).
            pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * resend e-mail verfication Link
     */
    CommonAccountVerifyService.prototype.resendverificationLink = function (formData) {
        return this.httpClient.post(this.baseUrl + "api/f/resend_email_verification_link", formData).
            pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * verify e-mail
     */
    CommonAccountVerifyService.prototype.verifyEmail = function (formData) {
        return this.httpClient.post(this.baseUrl + "api/f/verify_user_email", formData).
            pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * sign in user
     */
    CommonAccountVerifyService.prototype.SignInUser = function (formData) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + "api/f/user_signin", formData).
            pipe(map(function (user) {
            if (user && user.token) {
                _this.isUserLoggedIn = true;
                localStorage.setItem('isUserLoggedIn', 'true');
                localStorage.setItem('usertoken', user.token);
                _this.cookieService.set('userLogin', user.first_name, 1);
            }
            return user;
        }));
    };
    /** comment
     * forget password send link
     */
    CommonAccountVerifyService.prototype.forgetPassword = function (formData) {
        return this.httpClient.post(this.baseUrl + "api/f/user_forgot_password", formData).
            pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * forget verify token
     */
    CommonAccountVerifyService.prototype.forgetPasswordVerifyToken = function (formData) {
        return this.httpClient.post(this.baseUrl + "api/f/user_forgot_password_link_validate", formData).
            pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * user reset password
     */
    CommonAccountVerifyService.prototype.userResetPassword = function (formData) {
        return this.httpClient.post(this.baseUrl + "api/f/user_reset_password", formData).
            pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * user change password
     */
    CommonAccountVerifyService.prototype.passwordChange = function (userid, formData) {
        return this.httpClient.post(this.baseUrl + "api/user/change_user_password/" + userid, formData).
            pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * user informations
     */
    CommonAccountVerifyService.prototype.userInfo = function (userid) {
        return this.httpClient.get(this.baseUrl + "api/user/user_info/" + userid).
            pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * update profile details
     */
    CommonAccountVerifyService.prototype.updateProfile = function (userid, formData) {
        return this.httpClient.post(this.baseUrl + "api/user/update_user_profile/" + userid, formData).
            pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * update preference
     */
    CommonAccountVerifyService.prototype.updatePreferences = function (userid, formData) {
        return this.httpClient.post(this.baseUrl + "api/user/update_preferences", { user_id: userid, regular_updates: formData }).
            pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * get selected preference
     */
    CommonAccountVerifyService.prototype.selectedPreferences = function (userid) {
        return this.httpClient.post(this.baseUrl + "api/user/get_user_preferences", { user_id: userid }).
            pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * Request for volunteer
     */
    CommonAccountVerifyService.prototype.requestForVolunteer = function (testSlug, locations, userid) {
        return this.httpClient.post(this.baseUrl + "api/test/request_for_volunteer/" + testSlug + '/' + locations, { user_id: userid }).
            pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * volunteer task list
     */
    CommonAccountVerifyService.prototype.volunteerTaskList = function () {
        return this.httpClient.get(this.baseUrl + "api/test/volunteer_task_list").
            pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * apply for volunteer
     */
    CommonAccountVerifyService.prototype.volunteerApply = function (formData) {
        return this.httpClient.post(this.baseUrl + "api/test/volunteer_request_apply", formData).
            pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * cancel request for volunteer
     */
    CommonAccountVerifyService.prototype.cancelVolunteerRequest = function (userId, volunteerteskId) {
        // tslint:disable-next-line: max-line-length
        return this.httpClient.post(this.baseUrl + "api/test/volunteer_request_cancel", { user_id: userId, volunteer_request_id: volunteerteskId }).
            pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * delete my account
     */
    CommonAccountVerifyService.prototype.AccountDeleted = function (userId, userpass) {
        return this.httpClient.post(this.baseUrl + "api/user/delete_account", { user_id: userId, password: userpass }).
            pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * get support reasons
     */
    CommonAccountVerifyService.prototype.donationReasons = function () {
        return this.httpClient.get(this.baseUrl + "api/f/donation_reasons").pipe(map(function (res) {
            return res;
        }));
    };
    /** comment
     * check login service
     */
    CommonAccountVerifyService.prototype.checkLoggedIn = function () {
        var status = false;
        if (this.cookieService.get('userLogin') !== '') {
            if (localStorage.getItem('isUserLoggedIn') === 'true') {
                var token = localStorage.getItem('usertoken');
                if (token) {
                    status = true;
                }
                else {
                    status = false;
                }
            }
            else {
                status = false;
            }
        }
        else {
            localStorage.removeItem('userfname');
            localStorage.removeItem('userid');
            localStorage.removeItem('userlname');
            localStorage.removeItem('usertoken');
            localStorage.removeItem('isUserLoggedIn');
            this.isUserLoggedIn = false;
            status = false;
        }
        return status;
    };
    /** comment
     * set title on dashboard
     */
    CommonAccountVerifyService.prototype.setTitles = function (title) {
        this.title.next(title);
    };
    CommonAccountVerifyService.prototype.setUrl = function (link) {
        this.link.next(link);
    };
    /** comment
     * logout user
     */
    CommonAccountVerifyService.prototype.logout = function () {
        localStorage.removeItem('userfname');
        localStorage.removeItem('userid');
        localStorage.removeItem('userlname');
        localStorage.removeItem('usertoken');
        localStorage.removeItem('isUserLoggedIn');
        this.cookieService.delete('userLogin');
        this.isUserLoggedIn = false;
    };
    CommonAccountVerifyService.ngInjectableDef = i0.defineInjectable({ factory: function CommonAccountVerifyService_Factory() { return new CommonAccountVerifyService(i0.inject(i1.HttpClient), i0.inject(i2.CookieService)); }, token: CommonAccountVerifyService, providedIn: "root" });
    return CommonAccountVerifyService;
}());
export { CommonAccountVerifyService };
