import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule,  } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { httpInterceptorProviders } from './interceptors/index';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './guards/auth.guard';
import { CookieService } from 'ngx-cookie-service';
import { FrontLayoutModule } from '../app/front-layout/front-layout.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FrontLayoutModule,
    HttpClientModule,
    AngularFontAwesomeModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [AuthGuard, httpInterceptorProviders, Title, CookieService],
  bootstrap: [AppComponent],

})
export class AppModule { }
