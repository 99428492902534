import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonAccountVerifyService } from '../common-myaccount-service/common-account-verify.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {
    authToken: string;

    constructor(
        private authenticationService: CommonAccountVerifyService,
        private router: Router
    ) {}

  /** comment
   * verify user login and token
   */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (localStorage.getItem('isUserLoggedIn')) {
            this.authToken = localStorage.getItem('usertoken');
            req = req.clone({
                setHeaders:
                    {
                        Authorization: this.authToken
                    }
                }
            );
        }
        return next.handle(req).pipe(map(res => {
            if (res instanceof HttpResponse) {
                if (res.body.code === 401) {

                   this.router.navigate(['/signin']);
                }
            }
            return res;
            })
        );
    }
}
