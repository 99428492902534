<div class="clearfix"></div>
<div class="inner-banner" style=" background:url(assets/images/contact-banner.jpg); ">
    <div class="container">
        <h1 class="text-center sec-title"> <span>Upcoming Events</span></h1>
        <ol class="breadcrumb">
            <li class="active"><a href="index.php"><i class="fa fa-home"></i></a></li>
            <li class="active">Upcoming Events</li>
        </ol>
    </div>
</div>

<!-- carousel code end -->
<section class="team-wrapper vision res-padding">
    <div class="container">
       <div class="rows inception">
          <div class="col-md-6 editor" >
             <h2>{{fields[0].heading}}</h2>
             <div class="text">
                <div class="col-md-10 padding0" [innerHTML]="fields[0].content">
                </div>
             </div>
          </div>
          <div class="col-md-6 text-center" >
             <img src="{{fields[0].image}}" class="res-full" />
          </div>
       </div>
    </div>
</section>