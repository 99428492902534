import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FrontLayoutRoutingModule } from './front-layout-routing.module';
import { FrontLayoutComponent } from './front-layout.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { CmsserviceService} from '../common-cms-service/cmsservice.service';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { OwlModule } from 'ngx-owl-carousel';
import { UpcomingEventsComponent } from './upcoming-events/upcoming-events.component';
import { AcademyComponent } from './academy/academy.component';
@NgModule({
  declarations: [FrontLayoutComponent, HeaderComponent, FooterComponent, HomeComponent, UpcomingEventsComponent, AcademyComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    OwlModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    FrontLayoutRoutingModule
  ],
  providers: [ CmsserviceService, CookieService ],
  exports: [HeaderComponent, FooterComponent]
})
export class FrontLayoutModule { }
