<app-header></app-header>
<div
  class="inner-banner"
  style=" background:url(assets/images/team.jpg); height:100px; "
>
  <div class="container">
    <ol class="breadcrumb">
      <li class="active">
        <a href="index.php"><i class="fa fa-home"></i></a>
      </li>
      <li class="active"><a href="team.php" class="transition">Sehyog</a></li>
      <li class="active"></li>
    </ol>
  </div>
</div>
<div class="clearfix"></div>
<section class="team-wrapper">
  <div class="container">
    <div class="text-center">
      <img src="assets/images/404.jpg" class="res-full" />
    </div>
    <br />
    <br />
    <h2 class="text-center">Page Not Found</h2>
    <br />
    <div class="text-center">
      <a [routerLink]="['/']" class="btn btn-custom border btn-hover"
        >Take Me back Home
        <i class="fa fa-long-arrow-right" aria-hidden="true"></i
      ></a>
    </div>
  </div>
</section>

<app-footer></app-footer>
