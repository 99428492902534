import { Component, OnInit, AfterContentInit } from '@angular/core';
import { CmsserviceService } from '../../common-cms-service/cmsservice.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';
declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterContentInit {
  ngUnsubscribe = new Subject<void>();
  welcome: any;
  upcomingEventsrecord: any;
  images: any;
  banner: any;
  title = 'Sehyog';
  howithelp: any;
  siteSetting: any;
  constructor(
    private cmsService: CmsserviceService,
    private titleService: Title

  ) {
  }
  ngOnInit() {
    this.cmsService.commonSiteSettingSingleton().subscribe(res => {
      this.siteSetting = res;
    });
    $('.loader-bg').fadeIn();
    this.titleService.setTitle(this.title);
    this.welcomeToSehyog();
    this.upcomingTest();
    this.homeBanner();
    this.getTestCategoryList();
  }

  /** comment
   * welcome sehyog content home page
   */
  welcomeToSehyog() {
    this.cmsService.getCmsData('home')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.howithelp = res.data.fields[0].howItHelps;
        this.welcome = res.data.fields[0];
      });
  }

  /** comment
   * upcoming Session listing home page
   */
  upcomingTest() {
    this.cmsService.upcomingEventsRecord()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.images = res.data;
      });
  }

  /** comment
   * home banner
   */
  homeBanner() {
    this.cmsService.homeBanner()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.banner = res.data;
        $('.loader-bg').fadeOut();
      });
  }

  ngAfterContentInit(): void {
    $(document).ready(() => {
      $('.arrow-down').click(() => {
        const cls = $('.arrow-down').parent().attr('data-class');
        $('html, body').stop().animate({
          scrollTop: $('.' + cls).offset().top - 60
        }, 500);
      });

      $('.home-slider .slide').each(() =>  {
        $(this).css('background', 'url(' + $(this).find('img').attr('src') + ') no-repeat top center');
      });

    });
  }

  /** comment
   * get test category added on 02-03-2024
   */
  categoryListing: any = [];
  getTestCategoryList() {
    this.cmsService.getTestCategories()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.categoryListing = res.data;
      });
  }
}
