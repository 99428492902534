import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CommonAccountVerifyService {
  baseUrl = environment.baseURL;
  isUserLoggedIn = false;
  jwtHelper: any;
  redirectUrl: string;
  title = new BehaviorSubject('Initial Title');
  link = new BehaviorSubject('');
  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  /** comment
   * signup user form
   */
  singUpUser(formData: any): any {
    return this.httpClient.post<any>(`${this.baseUrl}api/f/user_signup`, formData).
      pipe(map(res => {
        return res;
      }));
  }

  /** comment
   * resend e-mail verfication Link
   */
  resendverificationLink(formData: any): any {
    return this.httpClient.post<any>(`${this.baseUrl}api/f/resend_email_verification_link`, formData).
      pipe(map(res => {
        return res;
      }));
  }

  /** comment
   * verify e-mail
   */
  verifyEmail(formData: any): any {
    return this.httpClient.post<any>(`${this.baseUrl}api/f/verify_user_email`, formData).
      pipe(map(res => {
        return res;
      }));
  }

  /** comment
   * sign in user
   */
  SignInUser(formData: any): any {
    return this.httpClient.post<any>(`${this.baseUrl}api/f/user_signin`, formData).
      pipe(map(user => {
        if (user && user.token) {
          this.isUserLoggedIn = true;
          localStorage.setItem('isUserLoggedIn', 'true');
          localStorage.setItem('usertoken', user.token);
          this.cookieService.set('userLogin', user.first_name , 1);
        }
        return user;
      }));
  }

  /** comment
   * forget password send link
   */
  forgetPassword(formData: any): any {
    return this.httpClient.post<any>(`${this.baseUrl}api/f/user_forgot_password`, formData).
      pipe(map(res => {
        return res;
      }));
  }

  /** comment
   * forget verify token
   */

  forgetPasswordVerifyToken(formData: any): any {
    return this.httpClient.post<any>(`${this.baseUrl}api/f/user_forgot_password_link_validate`, formData).
      pipe(map(res => {
        return res;
      }));
  }

  /** comment
   * user reset password
   */
  userResetPassword(formData: any): any {
    return this.httpClient.post<any>(`${this.baseUrl}api/f/user_reset_password`, formData).
      pipe(map(res => {
        return res;
      }));
  }

  /** comment
   * user change password
   */
  passwordChange(userid: any, formData: any): any {
    return this.httpClient.post<any>(`${this.baseUrl}api/user/change_user_password/` + userid, formData).
      pipe(map(res => {
        return res;
      }));
  }

  /** comment
   * user informations
   */
  userInfo(userid: any): any {
    return this.httpClient.get<any>(`${this.baseUrl}api/user/user_info/` + userid).
      pipe(map(res => {
        return res;
      }));
  }

  /** comment
   * update profile details
   */
  updateProfile(userid: any, formData: any): any {
    return this.httpClient.post<any>(`${this.baseUrl}api/user/update_user_profile/` + userid, formData).
      pipe(map(res => {
        return res;
      }));
  }

  /** comment
   * update preference
   */
   updatePreferences(userid: any, formData: any): any {
    return this.httpClient.post<any>(`${this.baseUrl}api/user/update_preferences`, {user_id: userid, regular_updates: formData}).
      pipe(map(res => {
        return res;
      }));
  }

  /** comment
   * get selected preference
   */
  selectedPreferences(userid: any): any {
    return this.httpClient.post<any>(`${this.baseUrl}api/user/get_user_preferences`, { user_id: userid }).
      pipe(map(res => {
        return res;
      }));
  }

  /** comment
   * Request for volunteer
   */
  requestForVolunteer(testSlug: any,locations: number, userid: any): any {
    return this.httpClient.post<any>(`${this.baseUrl}api/test/request_for_volunteer/` + testSlug + '/' + locations , { user_id: userid }).
      pipe(map(res => {
        return res;
      }));
  }

  /** comment
   * volunteer task list
   */
  volunteerTaskList(): any {
    return this.httpClient.get<any>(`${this.baseUrl}api/test/volunteer_task_list`).
      pipe(map(res => {
        return res;
      }));
  }

  /** comment
   * apply for volunteer
   */
  volunteerApply(formData: any): any {
    return this.httpClient.post<any>(`${this.baseUrl}api/test/volunteer_request_apply`, formData ).
      pipe(map(res => {
        return res;
      }));
  }

  /** comment
   * cancel request for volunteer
   */
  cancelVolunteerRequest(userId: any, volunteerteskId: any): any {
// tslint:disable-next-line: max-line-length
    return this.httpClient.post<any>(`${this.baseUrl}api/test/volunteer_request_cancel`, {user_id: userId, volunteer_request_id: volunteerteskId }).
      pipe(map(res => {
        return res;
      }));
  }

  /** comment
   * delete my account
   */
  AccountDeleted(userId: any, userpass: any): any {
  return this.httpClient.post<any>(`${this.baseUrl}api/user/delete_account`, {user_id: userId, password: userpass }).
      pipe(map(res => {
        return res;
      }));
  }

  /** comment
   * get support reasons
   */
donationReasons(): any {
  return this.httpClient.get<any>(`${this.baseUrl}api/f/donation_reasons`).pipe(map(res => {
      return res;
      })
  );
}

  /** comment
   * check login service
   */
  checkLoggedIn() {
    let status = false;
    if (this.cookieService.get('userLogin') !== '') {
    if (localStorage.getItem('isUserLoggedIn') === 'true') {
      const token = localStorage.getItem('usertoken');

      if (token) {
        status = true;

      } else {
        status = false;
      }
    } else {
      status = false;
    }
   } else {
    localStorage.removeItem('userfname');
    localStorage.removeItem('userid');
    localStorage.removeItem('userlname');
    localStorage.removeItem('usertoken');
    localStorage.removeItem('isUserLoggedIn');
    this.isUserLoggedIn = false;
    status = false;
   }
    return status;

  }

  /** comment
   * set title on dashboard
   */
  setTitles(title) {
    this.title.next(title);
  }

  setUrl(link) {
    this.link.next(link);
  }

  /** comment
   * logout user
   */
  logout() {
    localStorage.removeItem('userfname');
    localStorage.removeItem('userid');
    localStorage.removeItem('userlname');
    localStorage.removeItem('usertoken');
    localStorage.removeItem('isUserLoggedIn');
    this.cookieService.delete('userLogin');
    this.isUserLoggedIn = false;
  }
}
